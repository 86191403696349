import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Divider, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Modal, Fade } from '@mui/material';
import { Menu as MenuIcon, Star, EmojiEvents, Info, Feedback, Lock, Gavel, Close as CloseIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import logo from './out-noBg2.png';

const useStyles = makeStyles({
  appBar: {
    background: 'transparent !important',
    boxShadow: 'none',
    position: 'relative',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 10px',
    minHeight: '64px',
  },
  menuButton: {
    color: '#fff',
    marginRight: '10px',
  },
  logo: {
    height: '100%',
    maxHeight: '50px',
  },
  drawerPaper: {
    width: 300,
  },
  comingSoon: {
    fontSize: '0.8rem',
    color: 'grey',
    marginLeft: '10px',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#f9f9f9',
    padding: '20px 30px',
    borderRadius: '12px',
    maxWidth: '600px',
    width: '100%',
    maxHeight: '80vh',
    overflowY: 'auto',
    position: 'relative',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '10px',
  },
  modalTitle: {
    fontWeight: '700',
    fontSize: '1.5rem',
    color: '#333',
  },
  modalText: {
    marginBottom: '20px',  // Increased spacing between paragraphs
    lineHeight: '1.6',
    color: '#555',
  },
  highlightedText: {
    fontWeight: '600',
    color: '#007bff',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  sectionIcon: {
    marginRight: '10px',
    color: '#007bff',
  },
});

const Header = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleAbout = () => {
    setAboutOpen(!aboutOpen);
  };

  const toggleTerms = () => {
    setTermsOpen(!termsOpen);
  };

  const togglePrivacy = () => {
    setPrivacyOpen(!privacyOpen);
  };

  const menuItems = [
    { text: 'Badges', icon: <EmojiEvents />, comingSoon: true },
    { text: 'Kinding Extra', icon: <Star />, comingSoon: true },
    { text: 'Feedback/Report a problem', icon: <Feedback /> },
    { text: 'Privacy Policy', icon: <Lock />, onClick: togglePrivacy },
    { text: 'Terms of Service', icon: <Gavel />, onClick: toggleTerms },
    { text: 'About', icon: <Info />, onClick: toggleAbout },
  ];

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item) => (
          <ListItem button key={item.text} onClick={item.onClick || toggleDrawer(false)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
            {item.comingSoon && <Typography className={classes.comingSoon}>coming soon</Typography>}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon style={{ color: '#fff' }} />
          </IconButton>
          <img src={logo} alt='logo' className={classes.logo} />
        </Toolbar>
        <Divider style={{ backgroundColor: '#fff', height: '1px', width: '100%' }} />
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        {list()}
      </Drawer>
      
      {/* About Modal */}
      <Modal
        open={aboutOpen}
        onClose={toggleAbout}
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={aboutOpen}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography className={classes.modalTitle}>About</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleAbout}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography className={classes.modalText}>
              At KindingApp, we believe in the power of small acts of kindness to make a big difference.
              In a world that can sometimes feel overwhelming, a simple, thoughtful message can brighten 
              someone’s day and remind them that they’re loved.
            </Typography>
            <Typography className={classes.modalTitle}>What We Do</Typography>
            <Typography className={classes.modalText}>
              KindingApp is your tool for spreading positivity and joy. It allows you to send anonymous, heartfelt 
              messages to your friends, family, or anyone you care about. Whether it’s a word of encouragement, 
              a note of appreciation, or just a little reminder that they’re special, your messages can lift spirits 
              and spread smiles.
            </Typography>
            <Typography className={classes.modalTitle}>Our Mission</Typography>
            <Typography className={classes.modalText}>
              To create a kinder, more connected world, one message at a time. We want to inspire people to reach out, 
              even in small ways, to those they care about, fostering a sense of community and belonging.
            </Typography>
            <Typography className={classes.modalTitle}>Why Anonymous?</Typography>
            <Typography className={classes.modalText}>
              Sometimes, the best messages are the ones that come without any strings attached. By sending messages 
              anonymously, you’re focusing purely on the act of kindness itself—no expectations, no need for recognition, 
              just a pure, unselfish gesture to make someone’s day a little better.
            </Typography>
            <Typography className={classes.modalTitle}>Join Us</Typography>
            <Typography className={classes.modalText}>
              We’re more than just an app; we’re a movement. A movement towards more kindness, more love, and more understanding. 
              Whether you’re sending a message or receiving one, you’re part of something bigger—an ever-growing community of people 
              committed to making the world a brighter place.
            </Typography>
            <Typography className={classes.modalText}>
              So go ahead, start spreading kindness today. Because at KindingApp, every message matters.
            </Typography>
          </Box>
        </Fade>
      </Modal>

      {/* Terms of Service Modal */}
      <Modal
        open={termsOpen}
        onClose={toggleTerms}
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={termsOpen}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography className={classes.modalTitle}>Terms & Conditions</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleTerms}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography className={classes.modalText}>
              Welcome to KindingApp, where kindness is our currency, and goodwill is the goal. Before you dive into spreading joy, we’ve got some friendly guidelines to keep our community loving and kind.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>1. Good Vibes Only</span><br/>
              KindingApp is a safe space for sharing uplifting, positive messages. Any negativity, harassment, or unkind words will not be tolerated and may lead to a permanent ban from the Kindness Kingdom.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>2. Anonymity with Integrity</span><br/>
              While you’re free to stay anonymous, remember: with great anonymity comes great responsibility. Use it to uplift, not to harm. Our community thrives on trust and the expectation that kindness is always the motive.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>3. Cheerful Compliance</span><br/>
              By using KindingApp, you agree to spread joy and positivity. Any misuse of the app’s features, such as sending harmful or inappropriate content, will result in immediate removal from our community of kindness.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>4. Privacy with Purpose</span><br/>
              We respect your privacy and only collect the essentials to keep KindingApp running smoothly. Your personal information will never be sold or shared without your consent, because that’s just not kind.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>5. Kindness Karma</span><br/>
              What goes around comes around. Send positive energy, and positive energy will return to you. Abuse the app’s purpose, and you might find yourself on the receiving end of a digital timeout.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>6. Support System</span><br/>
              If you ever feel that something isn’t right or someone isn’t spreading the love, our support team is here to help. Reach out, and we’ll work to restore harmony in our community.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>7. Love & Legality</span><br/>
              KindingApp is all about love, but we still have to follow the law. Any activity that violates local, state, or international laws is strictly prohibited. This includes, but isn’t limited to, threats, bullying, or illegal content.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>8. Changes for Good</span><br/>
              As we grow, these terms might change to better serve our community. When we update, we’ll let you know, and by continuing to use KindingApp, you’re agreeing to our new rules of kindness.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>9. Gratitude</span><br/>
              Finally, thank you for being part of KindingApp. Your willingness to spread kindness makes the world a better place. We’re grateful to have you in our community.
            </Typography>
            <Typography className={classes.modalText}>
              By embracing these terms, you’re not just joining an app; you’re becoming a part of a movement that’s making the world a little bit brighter, one kind word at a time. Let’s make kindness contagious!
            </Typography>
          </Box>
        </Fade>
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal
        open={privacyOpen}
        onClose={togglePrivacy}
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={privacyOpen}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography className={classes.modalTitle}>Privacy Policy</Typography>
              <IconButton
                aria-label="close"
                onClick={togglePrivacy}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography className={classes.modalText}>
              Welcome to KindingApp! Your privacy is of utmost importance to us, and we’re committed to protecting it while you spread kindness and joy. Here’s everything you need to know about how we handle your data.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>1. The Information We Collect</span><br/>
              <strong>Basic Info:</strong> To create a KindingApp account, we might ask for your email address, username, and a password. This helps us keep your account safe and secure.<br/>
              <strong>Usage Data:</strong> We collect data about how you use the app, like the types of messages you send, how often you use the app, and other interactions. This helps us improve KindingApp and tailor the experience to our users.<br/>
              <strong>Device Information:</strong> We collect data about the device you use, like your IP address, device type, and operating system. This ensures the app runs smoothly on your device and helps us troubleshoot any issues.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>2. How We Use Your Information</span><br/>
              <strong>Spread Kindness:</strong> We use your information to help you spread kindness anonymously. Your personal data is never attached to the messages you send.<br/>
              <strong>App Improvement:</strong> Usage and device data help us understand how KindingApp is being used and how we can make it better for everyone.<br/>
              <strong>Communication:</strong> If there’s something important you need to know—like updates to our terms or a new feature—we’ll use your email to get in touch.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>3. Sharing Your Information</span><br/>
              <strong>We Don’t Sell Your Data:</strong> Ever. Period.<br/>
              <strong>Third-Party Services:</strong> We may share some data with trusted third-party services that help us run KindingApp (like cloud storage or analytics). These services are bound by strict confidentiality agreements and can’t use your data for anything other than supporting KindingApp.<br/>
              <strong>Legal Obligations:</strong> If required by law, we may have to share your information with authorities. We’ll always aim to protect your privacy to the fullest extent possible.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>4. Your Control Over Your Data</span><br/>
              <strong>Access & Update:</strong> You can access and update your personal information anytime through your account settings.<br/>
              <strong>Delete Your Data:</strong> Want to take a break? You can request to delete your account and all associated data by contacting our support team. We’ll make sure your data is permanently erased from our servers.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>5. Security Measures</span><br/>
              <strong>Keeping Your Data Safe:</strong> We use industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.<br/>
              <strong>Password Protection:</strong> Please keep your password safe and don’t share it with anyone. If you think someone has gained unauthorized access to your account, let us know right away.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>6. Children’s Privacy</span><br/>
              <strong>Under 13?:</strong> KindingApp isn’t intended for children under 13, and we don’t knowingly collect data from anyone under this age. If we discover that we’ve collected data from a child under 13, we’ll delete it immediately.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>7. Changes to This Policy</span><br/>
              <strong>Keeping You Informed:</strong> We might update this privacy policy from time to time. If we make significant changes, we’ll notify you either through the app or via email. By continuing to use KindingApp, you’re agreeing to any updates.
            </Typography>
            <Typography className={classes.modalText}>
              <span className={classes.highlightedText}>8. Contact Us</span><br/>
              <strong>Questions or Concerns?:</strong> If you have any questions about this privacy policy or how your data is handled, please reach out to our support team at kindingapp@gmail.com. We’re here to help.
            </Typography>
            <Typography className={classes.modalText}>
              By using KindingApp, you’re trusting us with your information, and we take that trust seriously. Thank you for being part of our kindness community!
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Header;
