import React from 'react';
import './AnimatedCheckmark.css';

const AnimatedCheckmark = () => {
  return (
    <div className="checkmark-container">
      <svg viewBox="0 0 52 52" className="checkmark">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#FFA500', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#FFD700', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </div>
  );
};

export default AnimatedCheckmark;
