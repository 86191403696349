import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import './QuickGuide.css';

const QuickGuide = ({ open, onClose, type, triggerAnimation }) => {
  const [step, setStep] = useState(0);
  const modalRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Trigger the fade-in animation on each step
    setShowMessage(false);
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 100); // Delay to allow the reset of opacity

    return () => clearTimeout(timer);
  }, [step]);

  if (!open) return null;

  const handleNext = () => {
    setShowMessage(false); // Reset animation
    if (step < 2) { // Assuming you have 3 steps (0, 1, 2)
      setStep((prevStep) => prevStep + 1);
    } else {
      onClose(); // Close the popup if it's the last step
    }
  };

  const handleBack = () => {
    setShowMessage(false); // Reset animation
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleSkip = () => {
    onClose();
  };

  const Indicator = ({ steps, currentStep }) => {
    return (
      <div className="indicator-container">
        {Array.from({ length: steps }).map((_, index) => (
          <span
            key={index}
            className={`indicator-dot ${index === currentStep ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (type === 'initial') {
      switch (step) {
        case 0:
          return (
            <>
              <Box className="quick-guide-image-container">
                <img src="PhoneWithoutMessage.png" alt="Phone Without Message" className="quick-guide-image" />
                {showMessage && (
                  <img src="Msg1.png" alt="Message Step 1" className="quick-guide-message fade-in" />
                )}
              </Box>
              <Box>
                <Typography variant="body1" className={`quick-guide-text ${showMessage ? 'slide-in' : ''}`}>
                Write a kind message to someone you care about.
                </Typography>
              </Box>
            </>
          );
        case 1:
          return (
            <>
              <Box className="quick-guide-image-container">
                <img src="PhoneWithoutMessage.png" alt="Phone Without Message" className="quick-guide-image" />
                {showMessage && (
                  <img src="Msg2.png" alt="Message Step 2" className="quick-guide-message fade-in" />
                )}
              </Box>
              <Box>
                <Typography variant="body1" className={`quick-guide-text ${showMessage ? 'slide-in' : ''}`}>
                 Your message is sent through Kinding, Your identity remains anonymous.
                </Typography>
              </Box>
            </>
          );
        case 2:
          return (
            <>
              <Box className="quick-guide-image-container">
                <img src="PhoneWithoutMessage.png" alt="Phone Without Message" className="quick-guide-image" />
                {showMessage && (
                  <img src="Msg3.png" alt="Message Step 3" className="quick-guide-message fade-in" />
                )}
              </Box>
              <Box>
                <Typography variant="body1" className={`quick-guide-text ${showMessage ? 'slide-in' : ''}`}>
                Add a personal touch if you’d like them to know it’s you.
                </Typography>
              </Box>
            </>
          );
        default:
          return null;
      }
    } else if (type === 'error') {
      return (
        <>
        <Box className="quick-guide-content">
            <Typography variant="body1" className="quick-guide-text-error">
              We strive to ensure that all messages sent through Kinding promote a positive and supportive environment. To maintain this standard, we use automated systems to detect and filter out messages that may contain inappropriate content. Your message might be rejected for the following reasons:
            </Typography>
            <Typography variant="h6" className="quick-guide-section-title">Reasons for Rejection:</Typography>
            <ul className="quick-guide-list">
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  <strong>Toxicity:</strong> Messages that contain harmful language, insults, or offensive terms are not allowed. Our system checks for any language that may be hurtful or damaging to others.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  <strong>Inappropriate Content:</strong> Messages that include sexual content, explicit language, or any form of harassment are prohibited. We aim to create a safe space for everyone.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  <strong>Negative Sentiment:</strong> Messages with a negative or discouraging tone can impact the positive atmosphere we are trying to foster. We encourage uplifting and supportive communication.
                </Typography>
              </li>
            </ul>
            <Typography variant="h6" className="quick-guide-section-title">What Should I Do?</Typography>
            <Typography variant="body1" className="quick-guide-text-error">
              Please review your message and ensure it adheres to the following guidelines:
            </Typography>
            <ul className="quick-guide-list">
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  Use kind and positive language.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  Avoid using any offensive or inappropriate terms.
                </Typography>
              </li>
              <li>
                <Typography variant="body1" className="quick-guide-text-error">
                  Make sure your message is encouraging and supportive.
                </Typography>
              </li>
            </ul>
            <Typography variant="body1" className="quick-guide-text-error">
              Once you have made the necessary adjustments, feel free to try sending your message again. Thank you for helping us create a positive community!
            </Typography>
          </Box>
        </>
      );
    }
  };

  return (
    <div className="quick-guide-overlay">
      <Box className="quick-guide-modal" ref={modalRef}>
        <div className="quick-guide-header">
          {type === 'initial' && (
            <Button onClick={handleSkip} className="quick-guide-skip-button">
              Skip
            </Button>
          )}
        </div>
        {renderContent()}
        <Box className="quick-guide-footer">
          {type === 'initial' ? (
            <>
              <Button
                onClick={handleBack}
                className={`quick-guide-button ${step === 0 ? 'disabled' : ''}`}
                disabled={step === 0}
              >
                Back
              </Button>
              <Indicator steps={3} currentStep={step} />
              <Button onClick={handleNext} className="quick-guide-button">
                Next
              </Button>
            </>
          ) : (
          <Box className="quick-guide-got-it-container">
            <Button onClick={onClose} className="quick-guide-button">
              Got it
            </Button>
          </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default QuickGuide;
