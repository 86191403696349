import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import './PhoneVerification.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

// Styled input for OTP
const StyledOtpInput = styled('input')({
  width: '100%',
  margin: '5px 0',
  textAlign: 'center',
  fontSize: '1.5em',
  letterSpacing: '0.6em',  // This creates the visual spacing between digits
  caretColor: 'transparent',  // Hide the caret to prevent it from being confusing
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  position: 'absolute',
  zIndex: 1,
  color: 'transparent',  // Hide the actual input text
});

const PhoneVerification = ({ onVerify, authMode, onPasswordResetComplete }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('20');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [isLogin, setIsLogin] = useState(authMode === 'login');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(authMode === 'signup' || authMode === 'login');
  const [buttonText, setButtonText] = useState(isLogin ? 'Sign in' : 'Continue');
  const [resetPasswordStep, setResetPasswordStep] = useState(false); // State for reset password flow

  const phoneInputRef = useRef(null);
  const otpInputRef = useRef(null);
  const phoneUtil = PhoneNumberUtil.getInstance();

  const startTimer = () => {
    setShowTimer(true);
    setTimer(60);
    setCanResend(false);

    let interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setCanResend(true);
          setShowTimer(false);
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (otpSent) {
      startTimer();
    }
  }, [otpSent]);

  const handlePhoneChange = (value, country) => {
    const numberWithoutCode = value.slice(country.dialCode.length);
    // Check if the selected country's dial code is Egypt's and the first digit after the country code is 0
    if (country.dialCode === '20' && numberWithoutCode.startsWith('0')) {
      setGeneralError("The phone number cannot start with '0' after the country code for Egypt.");
      return;
    }
    setPhoneNumber(numberWithoutCode);
    setCountryCode(country.dialCode);
    setPhoneError('');
    setGeneralError('');
  };

  const handleOtpChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
    if (value.length <= 4) {
      setOtp(value);
      setOtpError('');
      setGeneralError('');
    }
  };

  const validatePhoneNumber = () => {
    try {
      const fullNumber = `+${countryCode}${phoneNumber}`;
      const phoneNumberObj = phoneUtil.parse(fullNumber, '');
      if (!phoneUtil.isValidNumber(phoneNumberObj)) {
        setGeneralError('Invalid phone number format.');
        return false;
      }
      return true;
    } catch (error) {
      setGeneralError('Invalid phone number format.');
      return false;
    }
  };

  const handleResend = async () => {
    setLoading(true);
    setPhoneError('');
    setOtpError('');
    setGeneralError('');
    try {
      const response = await axios.post('https://kindingapp.com/send-otp', { phoneNumber: countryCode + phoneNumber, countryCode }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        setOtpSent(true);
        startTimer();
      }
      setLoading(false);
    } catch (error) {
      console.error('Error resending OTP:', error);
      setGeneralError('Failed to resend OTP. Please try again.');
      setLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    setLoading(true);
    setOtpError('');
    setGeneralError('');
    try {
      const response = await axios.post('https://kindingapp.com/verify-otp', { phoneNumber: countryCode + phoneNumber, otp }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        setResetPasswordStep(true); // Move to reset password screen only after OTP verification
      } else {
        setOtpError('Invalid OTP. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setOtpError('Invalid OTP. Please try again.');
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('https://kindingapp.com/reset-password', { phoneNumber: countryCode + phoneNumber, newPassword: password }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        onPasswordResetComplete(); // Move to the next required step (e.g., login or compose message)
      } else {
        setGeneralError('Failed to reset password. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error resetting password:', error);
      setGeneralError('Failed to reset password. Please try again.');
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!validatePhoneNumber()) return;

    setLoading(true);
    setPhoneError('');
    setGeneralError('');
    try {
      const response = await axios.post('https://kindingapp.com/send-otp', { phoneNumber: countryCode + phoneNumber, countryCode }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        setOtpSent(true); // OTP sent successfully
      } else {
        setGeneralError('Failed to send OTP. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error sending OTP for password reset:', error);
      setGeneralError('Failed to send OTP. Please try again.');
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    if (!validatePhoneNumber()) return;

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }
    setLoading(true);
    setPasswordError('');
    setGeneralError('');
    try {
      const response = await axios.post('https://kindingapp.com/signup', { phoneNumber: countryCode + phoneNumber, password, countryCode }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        setOtpSent(true);
        setShowPasswordFields(false);
      } else {
        if(response.data.error){
          var error="Invalid phone format. Please try again"
        }
        setGeneralError(error || 'Failed to sign up. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      setGeneralError('Failed to sign up. Please try again.');
      console.error('Error signing up:', error);
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    if (!validatePhoneNumber()) return;

    setLoading(true);
    setPasswordError('');
    setPhoneError('');
    setGeneralError('');
    try {
      const response = await axios.post('https://kindingapp.com/login', { phoneNumber: countryCode + phoneNumber, password }, { headers: { "Content-Type": "application/json" }, withCredentials: true });
      if (response.data.success) {
        
        onVerify(); // Proceed with the normal login flow
      } else {
        setPasswordError('Incorrect phone number or password. Please try again.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error logging in:', error);
      setGeneralError('Failed to log in. Please try again.');
      setLoading(false);
    }
  };

  const handleVerify = () => {
    // Placeholder function for the initial verification logic if required.
    // Implement your logic here as needed.
    if (isLogin) {
      handleLogin();
    } else {
      handleSignUp();
    }
  };

  return (
    <Box className="form-container">
      {!otpSent ? (
        <Box className="form-content">
          <Box mb={2} className="phone-input-container">
            <PhoneInput
              country={'eg'}
              value={countryCode + phoneNumber}
              onChange={(value, country) => handlePhoneChange(value, country)}
              countryCodeEditable={false}
              specialLabel="Your Phone Number"
              inputProps={{ ref: phoneInputRef }}
            />
          </Box>
          {showPasswordFields && (
            <Box mb={2} className="input-field" style={{ width: phoneInputRef.current ? phoneInputRef.current.offsetWidth : '100%', marginLeft: phoneInputRef.current ? phoneInputRef.current.offsetLeft : 10 }}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                  setGeneralError('');
                }}
                fullWidth
                margin="normal"
                size="small"
                className="password-field"
              />
              {!isLogin && (
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setPasswordError('');
                    setGeneralError('');
                  }}
                  fullWidth
                  margin="normal"
                  size="small"
                  className="password-field"
                />
              )}
            </Box>
          )}
          {(phoneError || otpError || passwordError || generalError) && (
            <Typography color="error" variant="body2" className="error-message">
              {phoneError || otpError || passwordError || generalError}
            </Typography>
          )}
          <Button
            variant="contained"
            className="form-button"
            onClick={showPasswordFields ? (isLogin ? handleLogin : handleSignUp) : handleVerify}
            disabled={loading || !phoneNumber}
          >
            {loading ? <CircularProgress size={24} /> : buttonText}
          </Button>
          {isLogin && (
            <Button
              variant="text"
              className="forgot-password-button"
              onClick={handleForgotPassword}
              disabled={loading || !phoneNumber}
            >
              Forgot your password?
            </Button>
          )}
        </Box>
      ) : resetPasswordStep ? (
        <Box className="form-content">
          <Typography variant="body1" className="success-message">
            Please enter your new password.
          </Typography>
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            className="password-field"
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            className="password-field"
          />
          {passwordError && (
            <Typography color="error" variant="body2" className="error-message">
              {passwordError}
            </Typography>
          )}
          <Button
            variant="contained"
            className="form-button"
            onClick={handleResetPassword}
            disabled={loading || !password || password !== confirmPassword}
          >
            {loading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>
        </Box>
      ) : (
        <Box className="form-content">
          <Typography variant="body1" className="success-message">
            Your 4-digit OTP has been sent on WhatsApp to +{countryCode}{phoneNumber}. Please enter the code to proceed.
          </Typography>
          <div className="otp-container">
            <div className="otp-boxes">
              {Array(4).fill('').map((_, index) => (
                <div key={index} className="otp-box">
                  {otp[index] || '_'}
                </div>
              ))}
              <StyledOtpInput
                value={otp}
                onChange={handleOtpChange}
                inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' }}  // Numeric input only
                ref={otpInputRef}
              />
            </div>
          </div>
          {otpError && (
            <Typography color="error" variant="body2" className="error-message">
              {otpError}
            </Typography>
          )}
          <Button
            variant="contained"
            className="form-button"
            onClick={handleOtpVerification}
            disabled={loading || otp.length !== 4}
          >
            {loading ? <CircularProgress size={24} /> : 'Verify OTP'}
          </Button>
          <Box display="flex" alignItems="center" marginTop="10px" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Didn't receive it?
              </Typography>
              <Button
                variant="text"
                className={`resend-button ${canResend ? 'active' : ''}`}
                onClick={handleResend}
                disabled={!canResend}
              >
                Resend Again
              </Button>
            </Box>
            {showTimer && (
              <Typography variant="body2" color="textSecondary" className="timer">
                Resend available in {timer} seconds
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PhoneVerification;
