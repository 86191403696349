import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, CircularProgress, Fade, Dialog, DialogContent, DialogContentText, DialogActions, Checkbox, FormControlLabel } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PhoneVerification from './PhoneVerification';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import './MessageForm.css';
import 'react-phone-input-2/lib/material.css';
import AnimatedCheckmark from './AnimatedCheckmark';
import { PhoneNumberUtil } from 'google-libphonenumber';

const MessageForm = ({ setShowWhyPopup, authMode }) => { // Accept authMode prop
    const [step, setStep] = useState(1); // Start at step 1 regardless of auth mode
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [recipientNumber, setRecipientNumber] = useState('');
    const [countryCode, setCountryCode] = useState('20'); // Default country code
    const [quotes, setQuotes] = useState([]);
    const [currentQuote, setCurrentQuote] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [remindMe, setRemindMe] = useState(false);
    const phoneInputRef = useRef(null);
    const phoneUtil = PhoneNumberUtil.getInstance();
    const [entered, setEntered] = useState(false); // State to manage the .entered class

    const getRandomQuote = (quotes) => {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        return quotes[randomIndex];
    };

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await fetch('/quotes.txt');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const text = await response.text();
                const quotesArray = text.split('\n').filter((quote) => quote.trim() !== '');
                setQuotes(quotesArray);
                setCurrentQuote(getRandomQuote(quotesArray));
            } catch (error) {
                console.error('Error fetching quotes:', error);
            }
        };

        fetchQuotes();
    }, []);

    useEffect(() => {
        if (quotes.length > 0) {
            const quoteInterval = setInterval(() => {
                setCurrentQuote(getRandomQuote(quotes));
            }, 10000);

            return () => clearInterval(quoteInterval);
        }
    }, [quotes]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setEntered(true); // Add the .entered class after the component mounts
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    const handlePhoneVerify = () => {
        setStep(2); // Move to step 2 after verification
    };

    const handlePasswordResetComplete = () => {
        setStep(2); // Proceed to the next step after password reset
    };

    const handlePhoneChange = (value, country) => {
        const numberWithoutCode = value.slice(country.dialCode.length);

        // Check if the selected country's dial code is Egypt's and the first digit after the country code is 0
        if (country.dialCode === '20' && numberWithoutCode.startsWith('0')) {
            setPhoneError("The phone number cannot start with '0' after the country code for Egypt.");
            return;
        }

        setRecipientNumber(numberWithoutCode);
        setCountryCode(country.dialCode);
        setPhoneError('');
    };

    const handleMessageChange = (e) => {
        if (e.target.value.length <= 350) {
            setMessage(e.target.value);
            setMessageError('');
        }
    };

    const validatePhoneNumber = () => {
        try {
            const fullNumber = `+${countryCode}${recipientNumber}`;
            const phoneNumberObj = phoneUtil.parse(fullNumber, '');
            if (!phoneUtil.isValidNumber(phoneNumberObj)) {
                setPhoneError('Invalid phone number format. Please try again.');
                return false;
            }
            return true;
        } catch (error) {
            setPhoneError('Invalid phone number format. Please try again.');
            return false;
        }
    };

    const handleSend = async () => {
        if (!validatePhoneNumber()) return;

        setLoading(true);
        setPhoneError('');
        setMessageError('');
        try {
            const response = await axios.post('https://kindingapp.com/send', {
                message,
                to: recipientNumber,
                countryCode
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true // Ensure cookies are sent
            });

            if (response && response.data) {
                console.log('Message sent successfully:', response.data);
                setStep(3); // Move to step 3 after sending message
            } else {
                console.error('Unexpected response format:', response);
                setMessageError('Unexpected response format');
            }
            setLoading(false);
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response);
                if (error.response.data.error && error.response.data.remainingTime) {
                    const remainingTime = error.response.data.remainingTime;
                    setDialogMessage(error.response.data.error);
                    setCountdown(calculateCountdown(remainingTime));
                    setDialogOpen(true);
                } else {
                    setMessageError(error.response.data.error || 'An error occurred');
                }
            } else {
                console.error('Error message:', error.message);
                setMessageError(error.message);
            }
            setLoading(false);
        }
    };

    const calculateCountdown = (remainingTime) => {
        const seconds = Math.floor((remainingTime / 1000) % 60);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {
        if (dialogOpen) {
            const interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    const { days, hours, minutes, seconds } = prevCountdown;
                    if (seconds > 0) {
                        return { ...prevCountdown, seconds: seconds - 1 };
                    } else if (minutes > 0) {
                        return { ...prevCountdown, minutes: minutes - 1, seconds: 59 };
                    } else if (hours > 0) {
                        return { ...prevCountdown, hours: hours - 1, minutes: 59, seconds: 59 };
                    } else if (days > 0) {
                        return { ...prevCountdown, days: days - 1, hours: 23, minutes: 59, seconds: 59 };
                    } else {
                        clearInterval(interval);
                        return prevCountdown;
                    }
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [dialogOpen]);

    const handleRemindMeChange = (event) => {
        setRemindMe(event.target.checked);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    return (
        <Box className={`message-form-container ${entered ? 'entered' : ''}`}>
            <Box className="quote">
                <Typography variant="subtitle1" style={{ fontFamily: 'Playpen Sans', fontSize: '1.2rem', fontWeight: 400 }}>
                    {currentQuote}
                </Typography>
            </Box>
            <Box>
            <Box className="progress-bar-container">
                    <Box className={`progress-bar-step ${step >= 1 ? 'active' : ''}`}>
                        <Typography variant="h6">1</Typography>
                        <Typography variant="caption" className="step-label">{authMode === 'login' ? 'Login' : 'Sign up'}</Typography>
                    </Box>
                    <Box className={`progress-bar-line ${step >= 2 ? 'active' : ''}`} />
                    <Box className={`progress-bar-step ${step >= 2 ? 'active' : ''}`}>
                        <Typography variant="h6">2</Typography>
                        <Typography variant="caption" className="step-label">Compose Message</Typography>
                    </Box>
                    <Box className={`progress-bar-line ${step >= 3 ? 'active' : ''}`} />
                    <Box className={`progress-bar-step ${step >= 3 ? 'active' : ''}`}>
                        <Typography variant="h6">3</Typography>
                        <Typography variant="caption" className="step-label">Message Delivered</Typography>
                    </Box>
                </Box>

                <Fade in={step === 1}>
                    <Box>
                        {step === 1 && <PhoneVerification onVerify={handlePhoneVerify} authMode={authMode} onPasswordResetComplete={handlePasswordResetComplete} />} {/* Pass authMode */}
                    </Box>
                </Fade>

                <Fade in={step === 2}>
                    <Box className="form-container">
                        {step === 2 && (
                            <>
                                <Box className="form-content">
                                    <Box mb={2} className="phone-input-container">
                                        <PhoneInput
                                            specialLabel="Recipient's phone number"
                                            country={'eg'}
                                            value={countryCode + recipientNumber}
                                            onChange={(value, country) => handlePhoneChange(value, country)}
                                            countryCodeEditable={false}
                                            inputProps={{ ref: phoneInputRef }}
                                        />
                                    </Box>
                                    {phoneError && (
                                        <Typography color="error" variant="body2" className="error-message">
                                            {phoneError}
                                        </Typography>
                                    )}
                                </Box>
                                <TextField
                                    label="Your Message"
                                    value={message}
                                    onChange={handleMessageChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    className="message-input"
                                />
                                <Box className="char-count-container">
                                    <Typography variant="body2" className="char-count">
                                        {message.length}/350
                                    </Typography>
                                </Box>
                                {messageError && (
                                    <Box>
                                        <Typography color="error" variant="body2" className="error-message">
                                            {messageError}
                                        </Typography>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => setShowWhyPopup(true)}
                                            startIcon={<InfoIcon />}
                                        >
                                            Why am I seeing this error?
                                        </Button>
                                    </Box>
                                )}
                                <Box className="button-container">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSend}
                                        disabled={loading}
                                        className="form-button"
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Send'}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Fade>

                <Fade in={step === 3}>
                    <Box className="sent-message">
                        {step === 3 && (
                            <>
                                <AnimatedCheckmark />
                                <Typography variant="h4" style={{ fontSize: '15px', color: '#849e95',marginTop: '20px' }}> Your message has been sent through Kinding.
                                </Typography>
                            </>
                        )}
                    </Box>
                </Fade>
            </Box>

            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
            >
                <DialogContent>
                    <Typography variant="h6" style={{color: '#849e95'}} align="center" gutterBottom className="positivity-title">
                        Thank you for spreading kindness!
                    </Typography>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                    <Box className="countdown-timer-rect">
                        <Typography variant="h6" className="countdown-number">{countdown.days}</Typography>
                        <Typography variant="body2" className="countdown-label">&nbsp;Days&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-colon">:&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-number">{countdown.hours}</Typography>
                        <Typography variant="body2" className="countdown-label">&nbsp;Hours&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-colon">:&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-number">{countdown.minutes}</Typography>
                        <Typography variant="body2" className="countdown-label">&nbsp;Mins&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-colon">:&nbsp;</Typography>
                        <Typography variant="h6" className="countdown-number">{countdown.seconds}</Typography>
                        <Typography variant="body2" className="countdown-label">&nbsp;Secs&nbsp;</Typography>
                    </Box>
                    <Typography variant="h6" style={{color: '#FFA500'}} align="center" gutterBottom className="positivity-title">
                        Keep the positivity rolling!
                    </Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={remindMe}
                                onChange={handleRemindMeChange}
                                name="remindMe"
                                color="primary"
                            />
                        }
                        label={<span className="remind-me-label">Remind me when I can send a message</span>}
                        className="remind-me-container"
                    />
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default MessageForm;
