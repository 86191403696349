import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Container, Box, Button, Fade } from '@mui/material'; // Import Fade
import { createTheme } from '@mui/material/styles';
import MessageForm from './components/MessageForm';
import Header from './components/Header';
import QuickGuide from './components/QuickGuide';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFA500',
    },
    secondary: {
      main: '#FFD700',
    },
    background: {
      default: '#f5f5dc',
    },
  },
  typography: {
    fontFamily: 'Playpen Sans, Arial, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    color: '#8b6f4b',
    button: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Caveat, Arial, sans-serif',
          fontSize: '1.2rem',
          fontWeight: 600,
        },
      },
    },
  },
});

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [showQuickGuide, setShowQuickGuide] = useState(false);
  const [showWhyPopup, setShowWhyPopup] = useState(false);
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [triggerMessageAnimation, setTriggerMessageAnimation] = useState(false);
  const [authMode, setAuthMode] = useState(null); // New state to control flow ("login" or "signup")

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
      setShowQuickGuide(true);
      setTriggerMessageAnimation(true); // Trigger the message animation after the splash screen
    }, 500); // Splash screen duration (adjust as needed)

    return () => clearTimeout(timer);
  }, []);

  const handleLoginClick = () => {
    setAuthMode('login');
    setShowMessageForm(true);
  };

  const handleSignUpClick = () => {
    setAuthMode('signup');
    setShowMessageForm(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={`splash-screen ${!showSplash && 'fade-out'}`}></div>
      {!showSplash && (
        <Box className="background-container">
          <Header />
          <Box className="banner-container">
            {!showMessageForm && (
              <Box className="button-group">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoginClick}
                  className="form-button"
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSignUpClick}
                  className="form-button"
                >
                  Sign Up
                </Button>
              </Box>
            )}
          </Box>
          <Container maxWidth="lg" id="message-form">
            <Fade in={showMessageForm} timeout={500}>
              <Box>
                {showMessageForm && <MessageForm setShowWhyPopup={setShowWhyPopup} authMode={authMode} />} {/* Pass authMode */}
              </Box>
            </Fade>
          </Container>
        </Box>
      )}
      <QuickGuide open={showQuickGuide} onClose={() => setShowQuickGuide(false)} type="initial" triggerAnimation={triggerMessageAnimation} />
      <QuickGuide open={showWhyPopup} onClose={() => setShowWhyPopup(false)} type="error" />
    </ThemeProvider>
  );
};

export default App;
